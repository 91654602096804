(function () {
  'use strict';

  /* @ngdoc object
   * @name nyuapp
   * @description
   *
   */
  angular
    .module('nyuapp', [
      'ui.router',
      'ui.bootstrap',
      'home',
      'user'
    ]);
}());
