<div id="wrapper" class="row">
    <div class="col-md-1">
        <h3 class="title">&nbsp;</h3>
        <button class="btn btn-success btn-block" onclick="$('#cover').trigger('click');"><i class="fa fa-upload"></i></button>  
        <button class="btn btn-danger btn-block" ng-click="vm.cover=false" ng-disabled="!vm.cover"><i class="fa fa-refresh"></i></button> 
        <button class="btn btn-success btn-block" ng-click="vm.savePic()" ng-disabled="!vm.dataSelected"><i class="fa fa-download"></i></button>  
        <input class="hide" type="file" name="file" id="cover" onchange="angular.element(this).scope().vm.photoChanged(this.files)" /> 
    </div>
    <div class="col-md-4 inherit">
        <h3 class="title">Editor</h3>
        <div id="droppable">
          <img src="images/algenist-woman.jpg" width="100%" ng-if="!vm.cover" id="model" border="1">
          <img ng-src="{{vm.cover}}" width="100%" ng-if="vm.cover" id="model">
        </div>
        <h3 class="title"></h3>
        <canvas id="leCanvas" class="hide"></canvas>
    </div>
    <div class="col-md-7 inherit" id="">
        <h3>Style</h3>
        <div id="drag-list" class="drag-list">
            <ul>
                <li ng-repeat="data in vm.datas">
                    <span id="drag{{data.id}}" key="{{data.id}}" class="drag" ng-click="vm.actionClick(data.id)">
                       <img src="images/hairs/{{data.file}}" width="100%" height="100%">
                    </span>
                </li>
               <!-- <li>
                    <div id="remove-drag" style="width: 100%; height: 80px; background-color: #FF8B8B;">
                        <div style="color: #fff; font-size: 20px; text-align: center; padding-top: 20px;">Remove
                            <div class="tip">Drop here</div>
                        </div>
                    </div>
                </li>-->
            </ul>
        </div>

        <div ng-if="vm.dataSelected.colors">
        <h3>Color of {{vm.dataSelected.name}}</h3>
        <div id="drag-list" class="drag-list">
            <ul>
                <li ng-repeat="color in vm.dataSelected.colors">
                    <span id="dragcolor{{color.id}}" key="{{color.id}}" class="drasg" sub="1">
                       <img src="images/hairs/{{color.file}}" width="100%" height="100%" ng-click="vm.changeColor(color.file)">
                    </span>
                </li>
            </ul>
        </div>
        </div>

    </div>
</div>