(function() {
    'use strict';

    /**
     * @ngdoc object
     * @name home.controller:HomeCtrl
     *
     * @description
     *
     */
    angular
        .module('home')
        .controller('HomeCtrl', HomeCtrl);

    function HomeCtrl($scope, $timeout) {
        var vm = this;
        vm.ctrlName = 'HomeCtrl';
        vm.datas = [{
                id: 1,
                name: 'model 1',
                file: 'img-thing-(1).png',
                colors: [
                    { id: 1, name: 'red', file: 'img-thing-red.png' },
                    { id: 2, name: 'blue', file: 'img-thing-blue.png' }
                ]
            },
            { id: 2, name: 'model 2', file: 'img-thing-(2).png' },
            { id: 3, name: 'model 3', file: 'img-thing-(3).png' },
            { id: 4, name: 'model 4', file: 'Very-Rare-Colored-Hairstyles-1.png',
              colors:[
                { id: 1, name: 'Pink', file: 'Very-Rare-Colored-Hairstyles-1-pink.png' },
              ]
             },
            { id: 5, name: 'model 5', file: 'hair_PNG5635.png' },
            { id: 6, name: 'model 6', file: 'img-thing.png' },
            { id: 7, name: 'model 7', file: 'img-thing.png' },
            { id: 8, name: 'model 8', file: 'img-thing.png' },
        ];



        $(document).ready(function() {
            vm.actionClick = actionClick;
            vm.photoChanged = photoChanged;
            vm.savePic = savePic;
            vm.changeColor = changeColor;
            var x = null;

            //Make element draggable
            iniDrag();

            $('#droppable').droppable({
                accept: '.drag',
                activeClass: 'drop-area',
                drop: function(e, ui) {
                    if ($(ui.draggable)[0].id !== '') {
                        console.log('drag action.. ', id);
                        var drgId = $(ui.draggable)[0].id;
                        var id = $('#' + drgId).attr('key');
                        var sub = $('#' + drgId).attr('sub');
                        if (!sub) getColor(id, 'drag');

                        x = ui.helper.clone();
                        ui.helper.remove();
                        initDrop(x);
                    }
                }
            });

            $('#remove-drag').droppable({
                drop: function(event, ui) {
                    $(ui.draggable).remove();
                },
                hoverClass: 'remove-drag-hover',
                accept: '.remove'
            });
            function changeColor(newColor){
              $('#hair').attr('src','images/hairs/'+newColor);
            }
            function actionClick(id) {
                console.log('click action.. ', id);
                var el = $('#drag' + id);
                var id = el.attr('key');
                var sub = el.attr('sub');
                if (!sub) getColor(id, 'click');

                x = el.clone();
                console.log(x.width())

                var box = document.getElementById('droppable');
                //var img1 = document.getElementById('model');
                var posBox = box.getBoundingClientRect();

                console.log('posBox', posBox);

                x.css({ "position": "absolute", "top": (posBox.top + 28) + "px", "left": (posBox.left + 90) + "px", "width": "200px" })
                x.removeAttr('id');
                initDrop(x);
            }
           // $( "#droppable" ).droppable( "option", "disabled", true ); // disable drag if onlin click allow
            function iniDrag() {
                $('.drag').draggable({
                    helper: 'clone',
                    cursor: 'move',
                    tolerance: 'fit',
                    revert: true
                });
            }

            function initDrop(clone) {
                $('.delete').parent().parent('span').remove();

                clone.draggable({
                    helper: 'original',
                    cursor: 'move',
                    containment: '#droppable',
                    tolerance: 'fit',
                    drop: function(event, ui) {
                        $(ui.draggable).remove();
                    }
                });

                clone.resizable({
                    maxHeight: $('#droppable').height(),
                    maxWidth: $('#droppable').width(),
                    handles: 'all'
                });
                clone.rotatable();
                clone.addClass('remove');
                clone.find('img').attr('id', 'hair');

                var el = $('<span><a href="Javascript:void(0)" class=\"xicon delete bg-danger\" title=\"Remove\">X</a></span>');
                $(el).insertAfter($(x.find('img')));

                var eleHandleNE = $("<div class='ui-resizable-handle ui-resizable-ne ui-icon ui-icon-triangle-1-ne' id='negrip'>");
                var eleHandleSE = $("<div class='ui-resizable-handle ui-resizable-se ui-icon ui-icon-triangle-1-se' id='segrip'>");
                var eleHandleSW = $("<div class='ui-resizable-handle ui-resizable-sw ui-icon ui-icon-triangle-1-sw' id='swgrip'>");
                var eleHandleNW = $("<div class='ui-resizable-handle ui-resizable-nw ui-icon ui-icon-triangle-1-nw' id='nwgrip'>");

                eleHandleNE.appendTo(x);
                eleHandleSE.appendTo(x);
                eleHandleSW.appendTo(x);
                eleHandleNW.appendTo(x);

                clone.appendTo('#droppable');

                $('.delete').on('click', function() {
                    $(this).parent().parent('span').remove();
                    $scope.$apply(function() { vm.dataSelected = false; });
                });
                $('.delete').parent().parent('span').dblclick(function() {
                    $(this).remove();
                    $scope.$apply(function() { vm.dataSelected = false; });
                });
            }

            function getColor(id, trg) {
                console.log('get color..', id);
                var dataSelected = _.filter(vm.datas, { 'id': parseInt(id) })[0];
                if (trg == 'click') { vm.dataSelected = dataSelected; }
                if (trg == 'drag') { $scope.$apply(function() { vm.dataSelected = dataSelected; }); }
                iniDrag();
            }

            function photoChanged(files) {
                console.log('file', files)
                if (files != null) {
                    var file = files[0];
                    if (file.type.indexOf('image') > -1) {
                        $timeout(function() {
                            var fileReader = new FileReader();
                            fileReader.readAsDataURL(file);
                            fileReader.onload = function(e) {
                                $timeout(function() {
                                    vm.cover = e.target.result;
                                    angular.forEach(angular.element("input[type='file']"), function(inputElem) { angular.element(inputElem).val(null); });
                                });
                            }
                        });
                    }
                }
            };

            function savePic() {
                console.log('save pic..');
                vm.readyToDownload = true;
                var canvas = document.getElementById("leCanvas");
                var context = canvas.getContext("2d");
                context.fillStyle = "#f04";
                context.fillRect(0, 0, canvas.width, canvas.height);

                var box = document.getElementById('droppable');
                var img1 = document.getElementById('model');
                var img2 = document.getElementById('hair');
                var posImg2Tmp = img2.getBoundingClientRect();
                var posBox = box.getBoundingClientRect();
                var posImg2 = {
                    top: posImg2Tmp.top - posBox.top,
                    left: posImg2Tmp.left - posBox.left,
                    bottom: posImg2Tmp.bottom - posBox.bottom,
                    right: posImg2Tmp.right - posBox.right
                }

                canvas.width = img1.width;
                canvas.height = img1.height;

                context.drawImage(img1, 0, 0, canvas.width, canvas.height);
                context.drawImage(img2, posImg2.left - 3, posImg2.top - 3, img2.width, img2.height);

                var download = document.createElement('a');
                download.href = canvas.toDataURL('image/jpeg');
                download.download = 'yourstyle.png';
                download.click();
            }



            /*
                var x = null;

                //Make element draggable
                $(".drag").draggable({

                    helper: 'clone',
                    cursor: 'move',
                    tolerance: 'fit',
                stack: '.drag',
                revert: "invalid"


                });

                        $("#droppable").droppable({

                            drop: function (e, ui) {

                                if ($(ui.draggable)[0].id != "") {
                                     x = ui.helper.clone();
                                     ui.helper.remove();
                                     x.draggable({
                                     
                                        //helper: 'original',
                                        containment: '#droppable',
                                        tolerance: 'fit',
                                        stack: '.drag'
                                    });

                                x.resizable({
                                  animate: true,
                                  //aspectRatio: 16 / 9,
                                  helper: "ui-resizable-helper",

                                });
                                x.appendTo('#droppable');

                            }

                            }
                        }); */

        });
    }
}());
